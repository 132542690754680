import React, { useState } from "react";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CategoryIcon from '@mui/icons-material/Category';

import DashboardIcon from '@mui/icons-material/Dashboard';

import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import { ROLE } from "app/rkt_query/SettingsReducer";
import { decryptStoredData } from "app/pages/storetoken/StoreTokenCheck";
import { Config } from "constant";
// useCheckRoles()
//  const GetFromRedux = () => {
//     const [allowances, setAllowances] = useState([]);
//  const roleName= useSelector(selectedUserLoginSelector)
//  setAllowances(roleName)  
//  return  allowances;

//     }
export const checkRole = (status) => {
    switch (status) {
        case ROLE.Admin:
        return ROLE.Admin;
      case ROLE.Manager:
        return ROLE.Manager;
      case ROLE.Owner:
        return ROLE.Owner;
      default:
        return ROLE.Manager;
      
    }
  };
  
  const role=decryptStoredData(Config.roleName)
  
  export const adminOwnerCondition=()=>{
//   const role_name=GetFromRedux();
    return (checkRole(role) === ROLE.Admin || checkRole(role) === ROLE.Owner);
  }
  export const adminOwnerManagerCondition=()=>{
//   const role_name=GetFromRedux();
    return (checkRole(role) === ROLE.Admin || checkRole(role) === ROLE.Owner|| checkRole(role) === ROLE.Manager);
  }
  export const adminCondition=()=>{

    return checkRole(role) === ROLE.Admin?true:false ;
  }
//   const adminManagerReceptionistCondition=()=>{

//     return checkRole("admin") === ROLE.Admin || checkRole("admin") === ROLE.Manager || checkRole("admin") === ROLE.Receptionist;
//   }




const menus = [
    
    {

        label: 'home',
        type: "section",
        children: [
            {
                uri: "/",
                label: 'Dashboard',
                type: "nav-item",
                visible: true,
                icon: <DashboardIcon sx={{ fontSize: 20 }} />,
            },
         
        ]

    },

   {
        label: 'modules',
        type: "section",
        children: [
             
            {
                uri: "/restaurants",
                label: 'Restaurant',
                type: "nav-item",
                visible: true,
                icon: <ImportContactsOutlinedIcon sx={{ fontSize: 20 }} />
            },
           
       
           

        ]

    },


  {

        label: 'authPages',
        type: "section",
        children: [
           
            // {
            //     label: 'Auth',
            //     type: "collapsible",
            //     icon: <GroupOutlinedIcon sx={{fontSize: 20}}/>,
            //     children: [
                
            //         {
            //             uri: "/admins",
            //             label: 'Admin',
            //             type: "nav-item",
            //             visible: true,
            //             icon: <SupervisorAccountOutlinedIcon sx={{ fontSize: 20 }} />,
            //             is_super:true
            //         },
                    
            //     ]
            // },
            {
                uri: "/admins",
                label: 'Admin',
                type: "nav-item",
                visible: true,
                icon: <SupervisorAccountOutlinedIcon sx={{ fontSize: 20 }} />,
                is_super:true
            },
            
            
            {
                uri: "/contacts",
                label: 'Contacts',
                type: "nav-item",
                visible: true,
                icon: <ContactPhoneOutlinedIcon sx={{ fontSize: 20 }} />,
                is_super:true
            },
          
        ]

    },
  

];



export default menus;



    