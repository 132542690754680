import React from "react";
import PropTypes from 'prop-types';
import {CopyBlock, a11yDark} from "react-code-blocks";

const JumboCodeBlock = ({text, language}) => {

    return (
      <h1>hello</h1>
    );
};

JumboCodeBlock.defaultProps = {
    language: "jsx"
};

JumboCodeBlock.propTypes = {
    file: PropTypes.string,
    language: PropTypes.string,
};

export default JumboCodeBlock;
