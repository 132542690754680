import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedAdminSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetAdminQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
import { Config } from 'constant';

const ViewAdmin = ({ selectId }) => {
    const selectedAdmin = useSelector(selectedAdminSelector);

    console.log(selectedAdmin)
    
    
    const { data: res_get, isLoading: isLoadingAdmin, error: errorAdmin } = useGetAdminQuery(selectedAdmin?.id);
    
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.admin?.image);
    useEffect(() => {
        res_get?.data?.admin?.image && setSelectedFile(res_get?.data?.admin?.image)
    }, [])

 

    const adminArray =
    [
      
        { label: 'Name', data: res_get?.data?.admin?.name },
        { label: 'Email', data: res_get?.data?.admin?.email },
       


    ]
    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {errorAdmin ? <div>{JSON.stringify(errorAdmin)}</div>
                : isLoadingAdmin ? <div>Loading Single Accessory.....</div>
                    : res_get?.data?.admin ?
                        <DZGenericView obj={adminArray} i closeBtn={true} /> 
                        : <div>Bad Code</div>}
            {/* {
                errorAdmin ? <div>{JSON.stringify(errorAdmin)}</div>
                    : isLoadingAdmin ? <div>Loading Single Admin.....</div>
                        : res_get?.data?.admin ?
                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: "5%" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }} >
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                        <label style={{ fontSize: "1em", fontWeight: 500 }}>Name</label>
                                        <label style={{ fontSize: "1em" }}>{res_get?.data?.admin?.name}</label>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                    <label style={{ fontSize: "1em", fontWeight: 500 }}>Email</label>
                                    <label style={{ fontSize: "1em" }}>{res_get?.data?.admin?.email}</label>
                                </div>
                                
                                <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                                    <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>

                                </div>
                 
                            </div>
                            : <div>Bad Code</div>
            } */}
        </div>
    )
}

export default ViewAdmin