export const dialogButtonsStyle = {
    // backgroundColor: 'rgba(52, 52, 52, 0.2)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 10,
    position: 'absolute',
    bottom: '5%',
    width: '94%',
    padding: 0
}

export const addUpdateDivStyleGlobal = { 
    marginTop: 25, 
    display: 'flex', 
    flexDirection: 'column', 
    gap: 25, 
    marginBottom: 60 
}