//export const ASSET_IMAGES = "process.env.REACT_APP_IMAGES_PATH";

import { Config } from "constant";

// const pathImage = "/admin-dzfood"

const pathImage = "/"+Config.endPointPath;
export const ASSET_IMAGES = pathImage + `/images`;
export const ASSET_AVATARS = ASSET_IMAGES + "/avatar";
export const ASSET_LOGOS = ASSET_IMAGES + "/logos";

