import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField } from '@mui/material';
import { selectedAdminSelector, setSelectedAdmin } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddAdminMutation, useGetAdminQuery,useGetAllRestaurantsQuery, useGetAllRolesQuery, useUpdateAdminMutation } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DZTextField from './../../../@jumbo/dz-components/textboxes/DZTextField';
import { useJumboDialog } from './../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import HeaderButtons from '@jumbo/dz-components/header-buttons/HeaderButtons';
import { Box,Fab } from '@mui/material';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZDropdown from '@jumbo/dz-components/dropdowns/DZDropdown';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';
const AddUpdateAdmin = ({ onClose }) => {

    const dispatch = useDispatch();
    const selectedAdmin = useSelector(selectedAdminSelector);

    const { data: res_get} = useGetAdminQuery(selectedAdmin?.id);
    const { data: res_get_all } = useGetAllRolesQuery();
    const [updateAdmin, responseUpdate,] = useUpdateAdminMutation();
    const [addAdmin, responseAdd,] = useAddAdminMutation();
    const bluePrint = BLUE_PRINTS.admin;
    const [adminState, setAdminState] = useState(null);
    const [roles, setRoles] = useState(null);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    // const { hideDialog } = useJumboDialog();
    const [showBackDrop, setShowBackDrop] = useState(false);
  
    // const [imagePreview, setImagePreview] = useState("");
    // const [selectedFile, setSelectedFile] = useState(res_get?.data?.admin?.image);
       //this code is use to control infinity loops and backend required error. custom hook
   const {error,resetCount}= useResponseError(responseAdd,responseUpdate);
    // const countZero = () => {
    //     setCount(true)
    // }
    // console.log(responseUpdate?.error?.data?.errors)
    // if (responseAdd?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseAdd?.error?.data?.errors)
    // }

    // if (responseUpdate?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseUpdate?.error?.data?.errors)
    // }
    const addUpdateAdminData = (action) => {

        const formData = new FormData();
        // formData.append('image', selectedFile);
        formData.append('id', res_get?.data?.admin?.id);
        formData.append('name', adminState.name);
        // formData.append('role_id', adminState.role_id);
        formData.append('email', adminState.email);
        formData.append('password', adminState.password);
      
        action === "add" ? addAdmin(formData) : updateAdmin(formData);
    
      }
    //console.log(error)

    useEffect(() => {
    
        setAdminState(selectedAdmin ?  res_get?.data?.admin: bluePrint);
        // setTimeout(() => {
        //   const rolesData = res_get_all?.data?.roles?.map((role) => role);
        //   setRoles(rolesData);
        // }, 1000);
    }, [res_get])


  
    // const filehandle = (e) => {
    //     console.log("file handle image", e.target.files[0])
    //     if (e.target.files.length !== 0) {
    
    //       setSelectedFile(e.target.files[0]);
        
    //       const reader = new FileReader();
    //       reader.onloadend = () => {
    //         setImagePreview(reader.result);
    //       };
    //       reader.readAsDataURL(e.target.files[0]);
    //     }
    //   };
    // const roles = res_get_all?.data?.roles?.map((roles) => (roles))

    return (
        <div>
            {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
            <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />

            {adminState ?
                <div style={{ marginTop: 25, display: 'flex', flexDirection: 'column', gap: 25 }}>
                    {/* <LanguageSwitcher /> */}
                    {/* {imagePreview ? (
            <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
          ) : (

            <DZImageCircle admin="admin" image={selectedFile} link={Config.digitalOceanLinkAdminSmallImg} style={{
              height: 100, width: 100, borderRadius: 50, alignSelf: "center",
            }}
            />

          )} */}

          {/* <Box sx={{ display: 'flex', paddingBottom: '0px', justifyContent: 'center', paddingTop: '0px' }}>
            <label htmlFor="upload-photo">
              <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>

            </label>
          </Box> */}
         
                {/* {roles&& <DZDropdown error={error?.role_id} fieldTextName={"name"}  value={adminState?.role_id} data={roles}
                  onChange={(e) => { setAdminState(x => ({ ...x, role_id: e.target.value, })) }} placeholder="Select Role" />} */}
       
                    <DZTextValidation label="Name" variant="outlined" value={adminState?.name}
                        onChange={(e) => { setAdminState(x => ({ ...x, name: e.target.value })) }}
                        error={error?.name}
                    />

                    <DZTextValidation label="Email" variant="outlined" value={adminState?.email}
                        onChange={(e) => { setAdminState(x => ({ ...x, email: e.target.value })) }}
                        error={error?.email}
                    />

                    <DZTextValidation label="Password" variant="outlined" value={adminState?.password}
                        onChange={(e) => { setAdminState(x => ({ ...x, password: e.target.value })) }}
                        error={error?.password}
                    />

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }} >
                        <Button variant="outlined" onClick={() => { onClose() }}>Cancel</Button>
                        {
                            selectedAdmin ? <Button variant="contained" onClick={() => { addUpdateAdminData(); setShowBackDrop(true); resetCount(); }}>Update</Button>

                                : <Button variant="contained" onClick={() => { addUpdateAdminData("add"); setShowBackDrop(true); resetCount();dispatch(setSelectedAdmin(null)); }}>Add</Button>
                        }
                        {/* dispatch(setSelectedAdmin(null)); */}
                    </div>
                    {/* <div style={{ width: '10%' }}>{JSON.stringify(adminState)}</div> */}
                </div>
                : <div>Loading....</div>
            }
            {/* {!selectedAdmin &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}

        </div>
    )
}

export default AddUpdateAdmin