import baseAxios from "axios";
const storedToken = "5|X7VFQ27sEtbI0wMlhyiRdwo35zg4YufqBI1g4tKz";
const axios = baseAxios.create({
    headers: {
        Authorization: "Bearer " + storedToken,
        'Content-Type': 'application/json'
    }
});

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default axios;