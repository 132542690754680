import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllContactsQuery, BLUE_PRINTS, useGetContactQuery, useDeleteContactMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { TITLE } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';

import { setSelectedContact } from 'app/rkt_query/SelectedStuffReducer';
import ViewContact from './ViewContact';

import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';

// import StoreTokenCheck from './../storetoken/StoreTokenCheck';

const ContactList = () => {
  const dispatch = useDispatch();
  
  const [deleteContact, responseDelete] = useDeleteContactMutation();
  const { data: res_get_all, isLoading: isLoadingContacts, error: errorContacts } = useGetAllContactsQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.contact
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedContact(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedContact(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedContact(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedContact(row)); }
  bluePrint.handleDelete = (id) => { deleteContact(id); }

  // const reloadPage = () => {
  //    window.location.reload()
  // }

  //if (responseDelete?.isSuccess) { reloadPage() }



  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title={TITLE.CONTACT}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable  showAddButton={true}
          addButtonHeader={false}
           hideEditBtn={false} //means hide 
           btnTitleAdd="Contact" data={res_get_all?.data?.contacts} bluePrint={bluePrint} ViewModel={() => <ViewContact />}  />
        
        </div>
      </JumboDemoCard>
    </>
  )
}


export default ContactList