/* eslint-disable default-case */
/* eslint-disable no-lone-blocks */
import { BLUE_PRINTS, useGetAllModulesQuery } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { selectedLanguageSelector } from '../../../app/rkt_query/SettingsReducer';
import { Article, Delete, Edit, Visibility } from '@mui/icons-material';
import HeaderButtons, { SimpleButtons } from '@jumbo/dz-components/header-buttons/HeaderButtons';
import { useJumboDialog } from '../../components/JumboDialog/hooks/useJumboDialog';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import './app.css';
import { useNavigate } from 'react-router-dom';
import ComboBox from '../autocomplete-cb/ComboBox';
import JumboDdMenu2 from '@jumbo/components/JumboDdMenu/JumboDdMenu2';
import { useLocation } from 'react-router-dom';
import { resetSettingsReducer, selectedIdSelector, setselectedId } from 'app/rkt_query/GlobalReducer';

import HeirarchyFlow from './HeirarchyFlow';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, CircularProgress } from '@mui/material';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { Table, TableBody } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import FastfoodIcon from '@mui/icons-material/Fastfood';

// import ActivityOrdering from 'app/pages/activity/ActivityOrdering';

import StoreTokenCheck from './../../../app/pages/storetoken/StoreTokenCheck';
const DataTable = ({
  onRow = () => { },
  viewTitle2,
  showHideViewTitle,
  onlyDeleteIcon=false,
  viewTitle,
  showAddButton,
  addButtonHeader=true,
  hideEditBtn=true,
  ddName,
  getAllData,
  showAutoComplete,
  heirarchyId,
  relationalObject,
  listData,
  companyName,
  translate,
  ddTranslate,
  btnTitleAdd,
  relationalTitle,
  teamCompanyId,
  data, isLoading,
  bluePrint = BLUE_PRINTS.category,
  RelationalModalContent = () => { },
  ModalContent = () => { },

  ViewModel = () => { } }) => {
  // console.log("bluePrint", bluePrint)
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const location = useLocation();
  const [undo, setUndo] = useState(location?.state?.relationalObject?.name ? true : false)
  const [selectHeader, setSelectHeader] = useState('Select');
  const selectedId = useSelector(selectedIdSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showDialog, hideDialog } = useJumboDialog();
  const [rows, setRows] = useState(data);
  const [input, setInput] = useState(relationalObject?.name ?? null)
  //const [input, setInput] = useState(handleLanguage(relationalObject?.name, selectedLanguage))
  const [pageSize, setPageSize] = React.useState(10);

  const columns = [...bluePrint.getCols(selectedLanguage),
  {
    field: viewTitle2, headerName: viewTitle2, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showAddButton,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
      console.log("this row ", params?.row)
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
       
          {
            btnTitleAdd === "Orders" &&
              (
              //last one is completed
                params?.row?.order_status=='pending'?<div style={{color:"#F29339"}}>{(params?.row?.order_status).toUpperCase()}</div>:
                params?.row?.order_status=='in-process'?<div style={{color:"#00b7eb"}}>{(params?.row?.order_status).toUpperCase()}</div>:
                params?.row?.order_status=='ready'?<div style={{color:"#0000FF"}}>{(params?.row?.order_status).toUpperCase()}</div>:
                params?.row?.order_status=='cancelled'?<div style={{color:"#D9512C"}}>{(params?.row?.order_status).toUpperCase()}</div>:
                params?.row?.order_status=='deleted'?<div style={{color:"#F61708"}}>{(params?.row?.order_status).toUpperCase()}</div>:<div style={{color:"#28a745"}}>{(params?.row?.order_status).toUpperCase()}</div>
              )
          }
        </div>
      )
    }
  },
  {
    field: viewTitle, headerName: viewTitle, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showHideViewTitle??true,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
      // console.log("this row ", params?.row?.courses?.[0])
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {/* <ControlPointOutlinedIcon /> */}
          {
           <FastfoodIcon  onClick={() => handleGoToCrud(thisRow)} /> 

          }
        </div>
      )
    }
  },
  {

    field: "action", headerName: "Actions", sortable: false, flex: 1, align: 'center', headerAlign: 'center',
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

      const showRecord = (e) => { e.stopPropagation(); bluePrint.showRecord(thisRow); showAddDialog(); };
      const editRecord = (e) => { e.stopPropagation(); bluePrint.editRecord(thisRow); showAddDialog(); };
      //   const deleteRecord = (e) => { e.stopPropagation(); bluePrint.deleteRecord(thisRow); deleteDialog(thisRow.id); };

      return (

        onlyDeleteIcon? <DeleteIcon onClick={() => deleteCourseUser(thisRow)} /> : <Box sx={{ display: 'flex', gap: 2 }}>
          <Visibility  onClick={() => handleItemAction({ title: "View", action: "view", row: thisRow })} />
        {  hideEditBtn&&<Edit  onClick={() => handleItemAction({ title: "Edit", action: "edit", row: thisRow })} />}
      <Delete  onClick={() => handleItemAction({ title: "Delete", action: "delete", row: thisRow })} />
        </Box>



        //code for Action buttons with 3 dots to open menu

        // btnTitleAdd !== "Course Users" ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        //   <JumboDdMenu
        //     menuItems={[
        //       // { icon: <Article />, title: "Goto", action: "goto", row: thisRow },
        //       { icon: <Visibility />, title: "View", action: "view", row: thisRow },
        //       { icon: <Edit />, title: "Edit", action: "edit", row: thisRow },
        //       { icon: <Delete />, title: "Delete", action: "delete", row: thisRow }

        //     ]}
        //     onClickCallback={handleItemAction}
        //   />
        // </div> : <DeleteIcon onClick={() => deleteCourseUser(thisRow)} />
      )
    }
  },
  ];

  useEffect(() => {
    //console.log("rows_rows", data[0]?.filter((item) => typeof Number(item?.company_id)))
    setRows(data);
    // setRows(filterNavigationData(relationalObject, data, btnTitleAdd))
    // { (btnTitleAdd === "Course") && setRows((relationalObject) ? (data?.filter((item) => item?.company_id == relationalObject?.id)) : data) }
    //{ heirarchyId && setRows((heirarchyId) ? (data?.filter((item) => item?.id === heirarchyId)) : data) }
  }, [data])

  const handleUpdateAllRows = (e) => {
    console.log("e", e)
    // var ch = handleDDOptionChange(btnTitleAdd, e, ddTranslate, data, selectedLanguage, getAllData)
    // setRows(ch)
    { !e && dispatch(resetSettingsReducer()) }
  };

  // const list = handleDDData(ddTranslate, listData, bluePrint, selectedLanguage)


  const handleSelectToo = (menuItem) => {

    // handleVisibilityAndAddIcon(menuItem, navigate, dispatch, btnTitleAdd, showDialog, relationalTitle)
  }

  
  const handleGoToCrud = (menuItem) => {

switch (btnTitleAdd) {
  case "Product":
    navigate("/product-accessories", {
      state: {
        relationalObject: menuItem,
      },
    });
    break;

  default:
    break;
}
   
  }
  const deleteCourseUser = (menuitem) => {

    showDialog({
      variant: 'confirm',
      title: 'Are you sure about deleting this ' + btnTitleAdd + '?',
      content: "You won't be able to recover this " + btnTitleAdd + ' later',
      onYes: () => handleDeleteDialogue(menuitem.id),
      onNo: hideDialog
    })
  }

  // handle action buttons
  const handleItemAction = (menuItem) => {
    // eslint-disable-next-line default-case
    //   console.log("handle", menuItem)

    const size = "xl";
    console.log("handle", menuItem.row);
    switch (menuItem.action) {
      case 'view':
        // if (btnTitleAdd === "Company" || btnTitleAdd === "User" || btnTitleAdd === "Admin") {
        bluePrint.showRecord(menuItem.row);
        showDialog({
          title: btnTitleAdd + ' Detail',
          size,
          content: <ViewModel />
        });

        // } else if (btnTitleAdd === "Course") {
        //   dispatch(setSelectedCategory(menuItem?.row))
        //   navigate("/modules", {
        //     state: {
        //       relationalObject: menuItem.row,
        //       company: "chekc",
        //     },
        //   });
        // } else if (btnTitleAdd === "Module") {

        //   showDialog({
        //     title: btnTitleAdd + ' Detail',
        //     content: <ViewModule selectId={menuItem?.row?.id} />
        //   });
        // }
        // else if (btnTitleAdd === "Topic") {

        //   bluePrint.showRecord(menuItem.row);

        // }
        // else if (btnTitleAdd === "learningJourney") {


        //   navigate(`/activities`, {
        //     state: {
        //       relationalObject: menuItem.row?.id,
        //       company: "chekc",
        //     },
        //   });
        // }
        // else if (btnTitleAdd === "Activity") {
        //   const size = btnTitleAdd === "Activity" ? '' : "xl";
        //   bluePrint.showRecord(menuItem.row);
        //   showDialog({
        //     title: btnTitleAdd + ' Detail',
        //     size,
        //     content: <ViewModel />
        //   });

        // }
        // else if (btnTitleAdd === "Activity Type") {
        //   bluePrint.showRecord(menuItem.row);
        //   showDialog({
        //     title: btnTitleAdd + ' Detail',
        //     content: <ViewModel />
        //   });

        // }
        break;

      // case 'view':
      //   bluePrint.showRecord(menuItem.row);
      //   showDialog({
      //     title: btnTitleAdd + ' Detail',
      //     content: <ViewModel />
      //   });
      //   break;
      case 'edit':
        bluePrint.editRecord(menuItem.row);
        showDialog({
          title: 'Update ' + btnTitleAdd,
          size,
          content: <ModalContent />
        });
        // if (btnTitleAdd === "Module") {
        //   bluePrint.showRecord(menuItem.row);
        //   showDialog({
        //     title: btnTitleAdd + ' Detail',
        //     content: <Module selectedId={menuItem?.row?.id} />
        //   });
        // }
        // else if (btnTitleAdd === "Topic") {
        //   //bluePrint.showRecord(menuItem.row);
        //   showDialog({
        //     title: btnTitleAdd + ' Detail',
        //     content: <AppUpdateTopic selectedId={menuItem?.row?.id} />
        //   });
        // }
        // else if (btnTitleAdd === "Company") {
        //   //bluePrint.showRecord(menuItem.row);
        //   dispatch(setSelectedProduct(menuItem?.row))
        //   showDialog({
        //     onClickCancel: 'testing',
        //     title: btnTitleAdd + ' Update',
        //     content: <ModalContent />
        //   });
        // }
        // // else if (btnTitleAdd === "Team") {
        // //   //bluePrint.showRecord(menuItem.row);
        // //   dispatch(setSelectedTeam(menuItem?.row))
        // //   showDialog({
        // //     onClickCancel: 'testing',
        // //     title: btnTitleAdd + ' Update',
        // //     content: <ModalContent />
        // //   });
        // // }
        // else if (btnTitleAdd === "Course") {

        //   dispatch(setSelectedCategory(menuItem?.row))

        //   showDialog({
        //     title: btnTitleAdd + ' Detail',
        //     content: <ModalContent selectedId={menuItem?.row?.id} />
        //   });
        // }
        // else if (btnTitleAdd === "learningJourney") {
        //   showDialog({
        //     title: btnTitleAdd + 'Update',
        //     content: <LearningJourney learningJourney={menuItem?.row} />
        //   });
        // }
        // else if (btnTitleAdd === "Activity") {


        // }
        break;
      case 'delete':
        showDialog({
          showLanguageSwitcher: false,
          variant: 'confirm',
          title: 'Are you sure about deleting this ' + btnTitleAdd + '?',
          content: "You won't be able to recover this " + btnTitleAdd + ' later',
          onYes: () => handleDeleteDialogue(menuItem.row.id),
          onNo: hideDialog
        })
    }
    // handleActionButtons(menuItem, btnTitleAdd, ViewModel, dispatch, navigate, bluePrint, showDialog, ModalContent)
  };

  const handleDeleteDialogue = (id) => {
    bluePrint.handleDelete(id);
    hideDialog();
  }

  const handleRowClick = (params) => {
    console.log(params)
    onRow(params)
  };

  const size = "xl";
  const showAddDialog = React.useCallback(() => {
    showDialog({
      title: "Add " + btnTitleAdd,
      size,
      content: <ModalContent />
    });
  }, [showDialog]);

  // const showOrderingDialog = React.useCallback(() => {
  //   showDialog({
  //     title: "Add " + btnTitleAdd,
  //     content: <TableContainer>
  //       <Table sx={{ minWidth: 650 }}>
  //         <TableBody>
  //           {rows?.map((contact, index) => (
  //             <ActivityOrdering key={index} index={index} contact={contact} />
  //           ))}
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //   });
  // }, [showDialog]);

  const handleHeirarchy = (item, index) => {
    // console.log("in func, ", item, index)
    var name;
    if (index === 0) { name = "/companies" }
    else if (index === 1) { name = "/courses" }
    else if (index === 2) { name = "/modules" }
    else if (index === 3) { name = "/topics" }
    else if (index === 4) { name = "/learning-Journeys" }
    //console.log("item", item, index)
    navigate(name, {
      state: {
        obj: item,
        company: item?.company
      }
    })
  }

  const handleCLoseIcon = () => {
    dispatch(resetSettingsReducer())
    if (btnTitleAdd === "Company") {
      setRows(data);
    }
    else {
      navigate("/companies", {
        state: { obj: null }
      })
    }
  }

  return (
    <div>
      <StoreTokenCheck />
      <HeirarchyFlow heirarchyId={heirarchyId} onClickCloseButton={handleCLoseIcon} onClickItem={handleHeirarchy} selectedId={selectedId} />
      <div style={{ width: '100%' }}>
        <div style={{ marginLeft: '10px', marginRight: '10px', display: 'flex', flexDirection: 'row', width: '100%' }} >

          {/* {(showAutoComplete && list) && (
            <div style={{ marginTop: '0px', display: 'flex', justifyContent: 'flex-start' }}>
              <ComboBox
                btnTitleAdd={btnTitleAdd}
                data={list}
                onChange={(event, value) => { handleUpdateAllRows(value); setUndo(false) }}
                //checkUndo={undo}
                value={ddName ? ddName : "Company"}
                defValue={input}
                inputChange={(e, val, reason) => setInput(val)}
                width={'200%'}
              />
            </div>)
          } */}
          {/* for showing header ADD button  */}
          {/* {(btnTitleAdd === "Category" || btnTitleAdd === "Course" || btnTitleAdd === "User" || btnTitleAdd === "Admin") && */}
          <div style={{ marginTop: '0px', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {addButtonHeader&&<HeaderButtons onClick={() => { showAddDialog(); bluePrint.addRecord(); }} />}
          </div>
          {/* } */}
          {/* {(btnTitleAdd === "Activity") && <SimpleButtons title={"Activity Ordering"} onClick={() => { showOrderingDialog(); }} />} */}

        </div>
      </div>
      <div style={{ height: 600, width: '100%', padding: '0px 20px 0px 20px', marginTop: '2%' }}>
        {rows ?
          <>

            <DataGrid
              sx={{
                '& .MuiDataGrid-toolbarContainer': {
                  pb: 2
                },
                '& MuiDataGrid-toolbarContainer-toolbarQuickFilter': {
                  m: 20,
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              rows={rows?.map((obj) => bluePrint.translate(obj, selectedLanguage))}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[5, 10, 20]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onRowClick={handleRowClick}
              pagination
              checkBoxSelection
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </>
          :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        }
      </div>
    </div>
  )
}

export default DataTable