
import React, { useEffect, useState } from 'react'
function useResponseError(responseAdd,responseUpdate="") {

    const [error, setError] = useState([]);
    const [count, setCount] = useState(true);
  useEffect(() => {
    if (responseAdd?.error?.data?.errors && count) {
      setCount(false);
      setError(responseAdd?.error?.data?.errors);
    }

    if (responseUpdate?.error?.data?.errors && count) {
      setCount(false);
      setError(responseUpdate?.error?.data?.errors);
    }
  }, [responseAdd, responseUpdate, count]);
  function resetCount()  {
    setCount(true)
  }
   
  return { error,resetCount };
}

export default useResponseError;