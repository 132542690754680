import React from 'react';
import {Bar, BarChart,Line, LineChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,Area,AreaChart,ReferenceLine } from 'recharts';
// import data from "./data";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";


const Chart = ({orders,domain}) => (
    <>
    {/* Bar chart for current year total orders */}
    <JumboDemoCard title={"Current Year Orders Monthly"}  wrapperSx={{pt: 0, backgroundColor: 'background.paper'}}>
        <ResponsiveContainer width="100%" height={400}>
            <BarChart  data={orders} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                <XAxis dataKey="name"/>
                <YAxis type="number" domain={domain} tickCount={6}/>
                <CartesianGrid strokeDasharray="3 3"   />
                <Tooltip content={<CustomTooltip/>} labelStyle={{color: 'black'}} itemStyle={{color: 'black'}} cursor={false}/>
                <Legend/>
                <defs>
                    <linearGradient x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#6200EE" stopOpacity={1}/>
                        <stop offset="95%" stopColor="#B819D2" stopOpacity={1}/>
                    </linearGradient>
                </defs>
                <defs>
                    <linearGradient x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1}/>
                        <stop offset="95%" stopColor="#09BCA7" stopOpacity={1}/>
                    </linearGradient>
                </defs>
                <Bar dataKey="pending" fill="#ffc658" animationBegin={0} animationDuration={1500} />
      <Bar dataKey="complete" fill="#82ca9d" animationBegin={1500} animationDuration={1500} />
      <Bar dataKey="cancelled" fill="#c9170e" animationBegin={3000} animationDuration={1500} />
      {/* <Bar dataKey="total"  /> */}
            </BarChart>
        </ResponsiveContainer>
    </JumboDemoCard>

    </>
);


export default Chart;

const CustomTooltip = ({ active, payload }) => {
    if (active && payload ) {
      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #999', padding: '10px' }}>
          <p>{` ${payload[0]?.payload?.name}`}</p>
          <p>{`Pending: ${payload[0]?.payload?.pending}`}</p>
          <p>{`Complete: ${payload[0]?.payload?.complete}`}</p>
          <p>{`Cancelled: ${payload[0]?.payload?.cancelled}`}</p>
          <p>{`Total: ${payload[0]?.payload?.total}`}</p>
        </div>
      );
    }
  
    return null;
  };
