import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedContactSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetContactQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
import { Config } from 'constant';

const ViewContact = ({ selectId }) => {
    const selectedContact = useSelector(selectedContactSelector);

    console.log(selectedContact)
    
    
    const { data: res_get, isLoading: isLoadingContact, error: errorContact } = useGetContactQuery(selectedContact?.id);
    
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.contact?.image);
    useEffect(() => {
        res_get?.data?.contact?.image && setSelectedFile(res_get?.data?.contact?.image)
    }, [])

 

    const contactArray =
    [
        { label: 'Name', data: res_get?.data?.contact?.name },
        { label: 'Email', data: res_get?.data?.contact?.email },
        { label: 'Subject', data: res_get?.data?.contact?.subject },
        { label: 'Message', data: res_get?.data?.contact?.message },
       


    ]
    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {errorContact ? <div>{JSON.stringify(errorContact)}</div>
                : isLoadingContact ? <div>Loading Single Accessory.....</div>
                    : res_get?.data?.contact ?
                        <DZGenericView obj={contactArray}  closeBtn={true} /> 
                        : <div>Bad Code</div>}
            {/* {
                errorContact ? <div>{JSON.stringify(errorContact)}</div>
                    : isLoadingContact ? <div>Loading Single Contact.....</div>
                        : res_get?.data?.contact ?
                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: "5%" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }} >
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                        <label style={{ fontSize: "1em", fontWeight: 500 }}>Name</label>
                                        <label style={{ fontSize: "1em" }}>{res_get?.data?.contact?.name}</label>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                    <label style={{ fontSize: "1em", fontWeight: 500 }}>Email</label>
                                    <label style={{ fontSize: "1em" }}>{res_get?.data?.contact?.email}</label>
                                </div>
                                
                                <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                                    <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>

                                </div>
                 
                            </div>
                            : <div>Bad Code</div>
            } */}
        </div>
    )
}

export default ViewContact