import React, { useState } from 'react';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { Table, TableBody, TablePagination, Grid, TableHead, TableCell, TableRow, TableSortLabel, Toolbar } from "@mui/material";

import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import TableContainer from "@mui/material/TableContainer";
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useSelector, useDispatch } from 'react-redux';
import OrderingData from './OrderingData';
import { BLUE_PRINTS, useOrderProductMutation, useOrderCategoryMutation, useOrderProductAccessoryMutation } from 'app/rkt_query/storeApis';
import { Box, Button, TextField, Fab } from '@mui/material';
import { useJumboDialog } from './../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import { VapingRooms } from '@mui/icons-material';







const Data = SortableContainer(({ datalists, table }) => {
    var bluePrint = '';
    switch (table) {
        case 'product':
            bluePrint = BLUE_PRINTS.product;
            break;
        case 'category':
            bluePrint = BLUE_PRINTS.category;
            break;
        case 'product_accessory':
            bluePrint = BLUE_PRINTS.productAccessory;
            break;

        default:
            console.error('No Order Match');
            break;
    }
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const { showDialog, hideDialog } = useJumboDialog();
    const [showBackDrop, setShowBackDrop] = useState(false);
    const [updateOrderProducts, responseUpdateProductOrder,] = useOrderProductMutation();
    const [updateOrderCategories, responseUpdateCategoryOrder,] = useOrderCategoryMutation();
    const [updateOrderProductAccessories, responseUpdateProductAccessoryOrder,] = useOrderProductAccessoryMutation();
  


    const updateOrders = (action) => {

        const formData = new FormData();

        formData.append('orders', JSON.stringify(datalists));
        switch (action) {
            case 'product':
                updateOrderProducts(formData);
                break;
            case 'category':
                updateOrderCategories(formData);
                break;
            case 'product_accessory':
                updateOrderProductAccessories(formData);
                break;
           
            default:
                console.error('No Order Match');
                break;
        }
    }
    return (
        <TableContainer>
            {showBackDrop && <SimpleBackdrop responseAdd={responseUpdateProductOrder} />}
            {showBackDrop && <SimpleBackdrop responseAdd={responseUpdateCategoryOrder} />}
            {showBackDrop && <SimpleBackdrop responseAdd={responseUpdateProductAccessoryOrder} />}
        
            <Snackbar responseAdd={responseUpdateProductOrder?.isSuccess === true && responseUpdateProductOrder} />
            <Snackbar responseAdd={responseUpdateCategoryOrder?.isSuccess === true && responseUpdateCategoryOrder} />
            <Snackbar responseAdd={responseUpdateProductAccessoryOrder?.isSuccess === true && responseUpdateProductAccessoryOrder} />
          
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow >

                        <TableCell width={"3%"} sx={{ pl: 3 }}>
                            Drag
                        </TableCell>
                        <TableCell width={"10%"} sx={{ pl: 3 }}>
                            Id
                        </TableCell>
                        <TableCell width={"20%"}>
                            Name
                        </TableCell>
                        {/* <TableCell width={"30%"}>
                            Description
                        </TableCell> */}
                        <TableCell width={"15%"}>
                            Order
                        </TableCell>

                    </TableRow>

                </TableHead>
                <TableBody>
                    {/* rows={rows?.map((obj) => bluePrint.translate(obj, selectedLanguage))} */}
                    {datalists?.map((item, index) => (
                        <OrderingData key={index} index={index} table={table} data={bluePrint.translate(item, selectedLanguage)} />
                    ))}
                </TableBody>
            </Table>
            <div style={dialogButtonsStyle}>
                <Button variant="outlined" onClick={() => { hideDialog(); }}>Cancel</Button>
                <Button variant="contained" onClick={() => { updateOrders(table); setShowBackDrop(true); }}>Save</Button>
            </div>
        </TableContainer>
    );
});

const Ordering = ({ dataList, table }) => {

    const [datalist, setDataList] = useState(dataList);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setDataList(arrayMove(datalist, oldIndex, newIndex));
    };
    console.log(datalist, 'datalist')
    return (
        <JumboDemoCard
            title={"Drag and drop"}

            wrapperSx={{
                p: 0,
                backgroundColor: 'background.paper',

                '&:last-child': {
                    pb: 0
                }
            }}
        >
            <Data datalists={datalist} table={table} onSortEnd={onSortEnd} useDragHandle={true} />
        </JumboDemoCard>
    );
};

export default Ordering;
