import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';

const FilesCounterCard = ({count,title,IconName,bgColor}) => {
    return (
        <JumboCardQuick bgColor={bgColor}>
            <Div sx={{display: "flex", alignItems: 'center'}}>
                <img alt={""} src={getAssetPath(`${ASSET_IMAGES}/dashboard/${IconName} `,"46x46")}/>
                
                <Div sx={{ml: 2, flex: 1}}>
                    <Typography color={'common.white'} variant={"h2"} mb={.5}>{count}</Typography>
                    <Typography color={'common.white'} variant={"h5"} mb={0}>{title}</Typography>
                </Div>
            </Div>
        </JumboCardQuick>
    );
};

export default FilesCounterCard;
