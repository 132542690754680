import React from 'react';
import {Line, LineChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,Area,AreaChart,ReferenceLine } from 'recharts';
// import data from "./data";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";


const LineChartOrder = ({orders,domain}) => (
    <>
   

{/* line chart for current year total orders */}
<JumboDemoCard title={"Current Year Total Orders Monthly "} wrapperSx={{pt: 0, backgroundColor: 'background.paper'}}>
        <ResponsiveContainer width="100%" height={400}>
      <LineChart data={orders} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                <XAxis dataKey="name" padding={{left: 30, right: 30}}/>
                <YAxis domain={domain}  />
                <CartesianGrid strokeDasharray="3 3"/>
                <Tooltip labelStyle={{color: 'black'}} itemStyle={{color: 'black'}} cursor={false}/>
                <Legend/>
              
                <Line type="monotone" dataKey="total" stroke={"#1e88e5"}   strokeWidth={2} activeDot={{r: 8}}  animation={{ duration: 2000,easing: 'ease-out', }} />
             
            </LineChart>
           
        </ResponsiveContainer>
    </JumboDemoCard>
   
    </>
);


export default LineChartOrder;

