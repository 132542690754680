import React, { useEffect, useState } from 'react';
import Avatar from "@mui/material/Avatar";
// import { authUser } from "./fake-db";
import { ListItemIcon, ListItemText, ThemeProvider, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import { useJumboTheme } from "@jumbo/hooks";
import PP2Service from './../../../services/index';
import { Config } from './../../../../constant/index';
import { LoginSnackbar } from '@jumbo/dz-components/snackbar/Snackbar';
import { Link } from "react-router-dom";


const AuthUserDropdown = () => {

    const navigate = useNavigate();
    const [authUser, setUser] = useState(null);

    const { theme } = useJumboTheme();
    useEffect(() => {
        userProfile()
    }, [])

    const userProfile = async () => {


        let response = await PP2Service.fetch("admin/me");

        if (response.success) {
            setUser(response.data)
            // setName(user?.name)


        } else {
            onLogout();
        }

    }
    console.log(authUser?.image);
    const onLogout = async () => {


        PP2Service.fetch("admin/logout").then(response => {

            // if (response.status === 200) {
            //     localStorage.removeItem(Config.adminApiTokenName)
            //     navigate("/")
            // }
            localStorage.removeItem(Config.adminApiTokenName)
            localStorage.removeItem(Config.roleName)
            navigate("/")

        });


    };

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        src={authUser?.image?Config.digitalOceanLinkAdminSmallImg+authUser?.image:authUser?.name}
                        sizes={"small"}
                        sx={{ boxShadow: 25, cursor: 'pointer' }}
                        alt={authUser?.name}
                    />
                }
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar  src={authUser?.image?Config.digitalOceanLinkAdminSmallImg+authUser?.image:authUser?.name} alt={authUser?.name} sx={{ width: 60, height: 60, mb: 2 }} />
                    <Typography variant={"h5"}>{authUser?.name}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{authUser?.email}</Typography>
                </Div>
                <Divider />
                <nav>
                    <List disablePadding sx={{ pb: 1 }}>
                        {/* <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <PersonOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="Profile" sx={{ my: 0 }} />
                        </ListItemButton> */}

                        <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <EditOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Edit Profile" onClick={() => navigate("/profile")} sx={{ my: 0 }} />
                        </ListItemButton>

                        {/* <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <RepeatOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText onClick={() => navigate("/samples/content-layout")} primary="Switch User"
                                sx={{ my: 0 }} />
                        </ListItemButton> */}
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{ my: 0 }} />
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
