import React from "react";

import Admin from "../pages/admin";
import User from "../pages/user";
import Login2 from "../pages/auth/login2/index"
import Dashboard from "app/pages/dashboard/Dashboard";
import Login from './../pages/auth/login/Login';
// import UserProfile from './../pages/users/user-profile/UserProfile';
import ForgotPassword from './../pages/auth/forgot-password/ForgotPassword';
import ForgotPasswordCode from './../pages/auth/forgot-password-code/ForgotPasswordCode';
import ChangePassword from './../pages/auth/forgot-password-code/ChangePassword';
import Profile from './../pages/profile/index';
import CategoryList from './../pages/category/index';
import ProductList from './../pages/product/index';
import AccessoryList from './../pages/accessory/index';
import RestaurantList from './../pages/restaurant/index';
import UpdateViewRestaurantInfo from './../pages/restaurant-info-time/UpdateViewRestaurantInfo';
import UpdateViewRestaurantTime from './../pages/restaurant-info-time/UpdateViewRestaurantTime';
import SizeGroupList from "app/pages/size_group";
import SizeList from "app/pages/size";
import ProductAccessoriesList from './../pages/product_accessory/index';
import OrderList from './../pages/order Sequence/index';
import Orders from './../pages/order/index';
import { adminCondition, adminManagerCondition, adminOwnerCondition, adminOwnerManagerCondition } from "app/layouts/shared/sidebars/Sidebar/menus";
import PoscAdminList from "app/pages/PoscAdmin";
import RestaurantTableList from "app/pages/restaurant_table";
import UpdateViewRestaurantFeatureInfo from "app/pages/restaurant-info-time/UpdateViewRestaurantFeature";
import ContactList from "app/pages/contact";





const routes = [

    { path: "/", element: <Login /> },
    { path: "forgot-password",element: <ForgotPassword />  },
    { path: "verify-forgot-password", element: <ForgotPasswordCode /> },
    { path: "change-password", element: <ChangePassword />},
    { path: "/login", element: <Login2 />},
    { path: "/profile",element: <Profile />},
    { path: "/dashboard",  element: <Dashboard />},
    { path: "/restaurants", element: <RestaurantList />},
    { path: "/contacts", element: <ContactList /> },
    { path: "/admins", element: <Admin /> },

    // { path: "/order-list",element: <OrderList /> },
      // adminOwnerManagerCondition()&& { path: "/product-accessories",element: <ProductAccessoriesList />},
      // adminOwnerManagerCondition()&&{ path: "/orders", element: <Orders />},
      // adminOwnerManagerCondition()&&{ path: "/categories",element: <CategoryList />},
      // adminOwnerManagerCondition()&&{ path: "/products", element: <ProductList /> },
      // adminOwnerManagerCondition()&&{ path: "/accessories",element: <AccessoryList />},
      // adminOwnerManagerCondition()&&{ path: "/size-groups",element: <SizeGroupList />},
      // adminOwnerManagerCondition()&&{ path: "/sizes", element: <SizeList /> },
      // adminOwnerCondition()&&{ path: "/restaurants", element: <RestaurantList />},
      // adminOwnerCondition()&&{ path: "/restaurant-table",element: <RestaurantTableList />},
      // adminCondition()&&{ path: "/restaurant-feature",element: <UpdateViewRestaurantFeatureInfo />},
      // adminOwnerCondition()&&{ path: "/restaurant-info",element: <UpdateViewRestaurantInfo />},
      // adminOwnerCondition()&&{ path: "/restaurant-time", element: <UpdateViewRestaurantTime /> },
      // adminOwnerCondition()&&{path: "/admins", element: <Admin /> },
      // adminOwnerCondition()&&{path: "/contacts", element: <ContactList /> },
      // adminOwnerCondition()&&{path: "/posc", element: <PoscAdminList /> },
      // adminOwnerCondition()&& { path: "/users",element: <User />},
      // adminCondition()&&{path: "/admins", element: <Admin /> },
      // adminCondition()&&{path: "/posc", element: <PoscAdminList /> },
      // adminCondition()&& { path: "/users",element: <User />},
 

];

export default routes;