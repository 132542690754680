import { createSlice } from '@reduxjs/toolkit';


export const slice = createSlice({
    name: 'SelectedStuffReducer',
    initialState: {
        selectedCategory: null,
        selectedUserLogin: null,
        selectedProduct: null,
        selectedAccessory: null,
        selectedProductAccessory: null,
        selectedUser: null,
        selectedAdmin: null,
        selectedContact: null,
        selectedPoscAdmin: null,
        selectedRestaurant: null,
        selectedRestaurantTable: null,
        selectedSizeGroup: null,
        selectedSize: null,

     
    

    },
    reducers: {
        setSelectedUserLogin: (state, action) => {
            state.selectedUserLogin = action.payload;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setSelectedProduct: (state, action) => {
            state.selectedProduct = action.payload;
        },

        setSelectedAccessory: (state, action) => {
            state.selectedAccessory = action.payload;
        },
        setSelectedProductAccessory: (state, action) => {
            state.selectedProductAccessory = action.payload;
        },
        setSelectedRestaurant: (state, action) => {
            state.selectedRestaurant = action.payload;
        },
        setSelectedRestaurantTable: (state, action) => {
            state.selectedRestaurantTable = action.payload;
        },

        setSelectedUser: (state, action) => {
            console.log("redux data", action)
            state.selectedUser = action.payload;
        },
        setSelectedAdmin: (state, action) => {
            state.selectedAdmin = action.payload;
        },
        setSelectedContact: (state, action) => {
            state.selectedContact = action.payload;
        },
        setSelectedPoscAdmin: (state, action) => {
            state.selectedPoscAdmin = action.payload;
        },
        setSelectedSizeGroup: (state, action) => {
            console.log("redux data", action)
            state.selectedSizeGroup = action.payload;
        },
        setSelectedSize: (state, action) => {
            state.selectedSize = action.payload;
        },
        //old
 
   
        // setSelectedUser: (state, action) => {
        //     state.selectedUser = action.payload;
        // },
        resetSelectedStuffReducer: (state) => {
            state.selectedUserLogin = null;
            state.selectedCategory = null;
            state.selectedProduct = null;
            state.selectedAccessory = null;
            state.selectedProductAccessory = null;
            state.selectedUser = null;
            state.selectedAdmin = null;
            state.selectedContact = null;
            state.selectedRestaurantTable = null;
            state.selectedPoscAdmin = null;
            state.selectedSizeGroup = null;
            state.selectedSize = null;

          
        },
    },
});

export const {
    setSelectedUserLogin,
    setSelectedCategory,
    setSelectedProduct,
    setSelectedAccessory,
    setSelectedProductAccessory,
    setSelectedRestaurant,
    setSelectedRestaurantTable,
    setSelectedUser,
    setSelectedAdmin,
    setSelectedContact,
    setSelectedPoscAdmin,
    setSelectedSizeGroup,
    setSelectedSize,
    //old
 
} = slice.actions;
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const selectedUserLoginSelector = (state) => state.SelectedStuffReducer.selectedUserLogin;
export const selectedCategorySelector = (state) => state.SelectedStuffReducer.selectedCategory;
export const selectedProductSelector = (state) => state.SelectedStuffReducer.selectedProduct;
export const selectedAccessorySelector = (state) => state.SelectedStuffReducer.selectedAccessory;
export const selectedProductAccessorySelector = (state) => state.SelectedStuffReducer.selectedProductAccessory;
export const selectedRestaurantSelector = (state) => state.SelectedStuffReducer.selectedRestaurant;
export const selectedRestaurantTableSelector = (state) => state.SelectedStuffReducer.selectedRestaurantTable;
export const selectedUserSelector = (state) => state.SelectedStuffReducer.selectedUser;
export const selectedAdminSelector = (state) => state.SelectedStuffReducer.selectedAdmin;
export const selectedContactSelector = (state) => state.SelectedStuffReducer.selectedContact;
export const selectedPoscAdminSelector = (state) => state.SelectedStuffReducer.selectedPoscAdmin;
export const selectedSizeGroupSelector = (state) => state.SelectedStuffReducer.selectedSizeGroup;
export const selectedSizeSelector = (state) => state.SelectedStuffReducer.selectedSize;
//old



export default slice.reducer;