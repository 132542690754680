const serverUrl = process.env.REACT_APP_SERVER_URL;
const endPointPath = process.env.REACT_APP_END_POINT_PATH;
const appName=process.env.REACT_APP_NAME;
const path=process.env.REACT_APP_PATH;
const tableOrderLink=process.env.REACT_APP_TABLE_ORDER;



export const Config = {

  endPointPath:endPointPath,
  projectName:appName,
  tableOrderLink:tableOrderLink,
  path:path,
  secretPass: "XkhZG4fW2t2W",
  serverUrl: serverUrl,
  serverApiUrl: serverUrl + 'api/',
  serverUrlImages: serverUrl + 'public/images/',
  serverUrlCategoryImages: serverUrl + 'images/categories/',
  serverUrlProductImages: serverUrl + 'public/images/products/',
  serverUrlUserImages: serverUrl + 'public/images/users/',
  adminApiTokenName: 'admin-login-token',
  roleName: 'role',
  email: 'email',
  verificationCode: 'verifiactionCode',

  userApiTokenName: 'user-login-token',
  googleApiKey: 'AIzaSyD4BUDTEpTPIuhhJ2MyQ4AiQ0u3CyFlWOo',

  // Used in whole app
  currency_symbol: "DKK",
  currency: "Kr.",

  appName: appName,

  pushTokenName: 'device-push-token',

  // Here add langues and then add transaltion file for languages
  languages: [
    { shortName: 'en', icon: '', longName: 'English' },
    { shortName: 'da', icon: '', longName: 'Danish' }
  ],
  firstVisitDone: "firstVisitDone",

  defaultActivityImg: "../select-image.jpg",
  defaultImg: "select-image.jpg",
  defaultProductImageURI: 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg',

  digitalOceanLinkProductSmallImg: process.env.REACT_APP_PRODUCT_IMAGE_SMALL_LINK,
  // digitalOceanLinkProductLargeImg: `https://danzee.fra1.digitaloceanspaces.com/${path}/admin/images/products/large/`,
  // digitalOceanLinkProductMediumImg: `https://danzee.fra1.digitaloceanspaces.com/${path}/admin/images/products/medium/`,
  // category paths
  digitalOceanLinkCategorySmallImg:  process.env.REACT_APP_CATEGORY_IMAGE_SMALL_LINK,
  // digitalOceanLinkCategoryLargeImg: `https://danzee.fra1.digitaloceanspaces.com/${path}/admin/images/categories/large/`,
  // digitalOceanLinkCategoryMediumImg: `https://danzee.fra1.digitaloceanspaces.com/${path}/admin/images/categories/medium/`,
  // Admin image paths
  digitalOceanLinkAdminSmallImg: process.env.REACT_APP_ADMIN_IMAGE_SMALL_LINK,
  // digitalOceanLinkAdminLargeImg: `https://danzee.fra1.digitaloceanspaces.com/${path}/admin/images/admin/large`,
  // digitalOceanLinkAdminMediumImg: `https://danzee.fra1.digitaloceanspaces.com/${path}/admin/images/admin/medium`,
  // User image paths
  digitalOceanLinkUserSmallImg: process.env.REACT_APP_USER_IMAGE_SMALL_LINK,
  // digitalOceanLinkUserLargeImg: `https://danzee.fra1.digitaloceanspaces.com/${path}/admin/images/user/large/`,
  // digitalOceanLinkUserMediumImg: `https://danzee.fra1.digitaloceanspaces.com/${path}/admin/images/user/medium/`,

  // Restaurant image paths
  digitalOceanLinkRestaurantSmallImg: process.env.REACT_APP_RESTAURANT_IMAGE_SMALL_LINK,
  // digitalOceanLinkRestaurantLargeImg: `https://danzee.fra1.digitaloceanspaces.com/${path}/admin/images/restaurant/large/`,
  // digitalOceanLinkRestaurantMediumImg: `https://danzee.fra1.digitaloceanspaces.com/${path}/admin/images/restaurant/medium/`,
}

