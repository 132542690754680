import React from 'react'
import { useNavigate } from "react-router-dom";
import { Config } from './../../../constant/index';
import PP2Service from './../../services/index';
import { useDispatch, useSelector } from 'react-redux';
import { selectedUserLoginSelector } from 'app/rkt_query/SelectedStuffReducer';
import CryptoJS from "crypto-js";


const StoreTokenCheck = () => {

    // const selectedUserLogin = useSelector(selectedUserLoginSelector);
    // console.log(selectedUserLogin,"asdasd");
    const navigate = useNavigate();
    console.log('Token checkaaa');
    React.useEffect(() => {

        const response = PP2Service.fetch("admin/me", {}).then((result) => {
            if (result?.success) {

            } else {
                
                localStorage.removeItem(Config.adminApiTokenName)
                localStorage.removeItem(Config.roleName)
                navigate("/");
            }
        }).catch();
        const res=JSON.stringify(response);
        if (!res) {
            localStorage.removeItem(Config.adminApiTokenName)
            localStorage.removeItem(Config.roleName)
            navigate("/");
        }
    //    const storedToken= decryptStoredData(Config.adminApiTokenName);

    //     if (!storedToken) {
    //         navigate('/')
    //     }




    }, []);
    // const handler = async () => {


    //     let response = await PP2Service.fetch("admin-login-check");
    //     console.log("response", response)
    //     if (response?.success) {

    //         localStorage.removeItem(Config.adminApiTokenName)
    //         navigate("/");


    //     }

    // }
    // handler();

    return (
        <>

        </>
    )
}

export default StoreTokenCheck

// export const decryptStoredData=(key)=>{
//     const decrypt_token = localStorage.getItem(key)
//     if(decrypt_token){
//         const bytes = CryptoJS.AES.decrypt(decrypt_token, Config.secretPass);
//         const storedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//         return storedToken;
//     }
   
// }
export const decryptStoredData = (key) => {
    const decrypt_token = localStorage.getItem(key);
    if (decrypt_token) {
        try {
            const bytes = CryptoJS.AES.decrypt(decrypt_token, Config.secretPass);
            const storedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return storedToken;
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle the error or return a default value as needed
        }
    }
    return null; // Or any default value if the data is not available or not valid JSON
};

export const encryptStoredData=(key,data)=>{
   const encrypt_token = CryptoJS.AES.encrypt(
                    JSON.stringify(data),
                    Config.secretPass
                  ).toString();
                  localStorage.setItem(
                    key,
                    encrypt_token
                );
}