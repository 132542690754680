import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedRestaurantSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetRestaurantQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DZLabel, { DZDataLabel, DZLabelDescription, DZTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import { Config } from 'constant';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';


const ViewRestaurant = () => {
  
    const selectedRestaurant = useSelector(selectedRestaurantSelector);
    const { data: res_get, isLoading: isLoadingRestaurant, error: errorRestaurant } = useGetRestaurantQuery(selectedRestaurant?.id);
    const selectedLanguage = useSelector(selectedLanguageSelector);
   
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.restaurant?.image);

    useEffect(() => {
        res_get?.data?.restaurant?.image && setSelectedFile(res_get?.data?.restaurant?.image)
    }, [])

    const restaurantArray =
        [
        { label: 'Name', data: res_get?.data?.restaurant?.title },
        { label: 'email', data: res_get?.data?.restaurant?.email },
        { label: 'Phone', data: res_get?.data?.restaurant?.phone },
        { label: 'Web Url', data: res_get?.data?.restaurant?.web_url },
        { label: 'Admin Url', data: res_get?.data?.restaurant?.admin_url },

        { label: 'Address', data: res_get?.data?.restaurant?.address},
        { label: 'Quote', data: res_get?.data?.restaurant?.reference?.quote?.[selectedLanguage] },
        { label: 'Description', data: res_get?.data?.restaurant?.detail?.[selectedLanguage] },
    ]

        




    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {
                errorRestaurant ? <div>{JSON.stringify(errorRestaurant)}</div>
                    : isLoadingRestaurant ? <div>Loading Single Restaurant.....</div>
                        : res_get?.data?.restaurant ?
                            <>
                                <DZGenericView obj={restaurantArray} imagePath={Config.AWSRestaurantOriginalImg} image={selectedFile} checkImage={true} closeBtn={true} />


                            </>

                            : <div>Bad Code</div>
            }
        </div>
    )
}

export default ViewRestaurant